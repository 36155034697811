@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

.nav-link {
  position: relative;
  padding-bottom: 2px;
  transition: color 0.3s ease;
}
.nav-link:hover {
  color: #eeeeee;
}
.nav-link::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #6366f1;
  transform: scaleX(0);
  transition: transform 0.3s ease;
  transform-origin: right;
}
.nav-link:hover::after {
  transform: scaleX(1);
  transform-origin: left;
}
/* Frosted Glass Effect for Scrolled Navbar */
.bg-glass {
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(10px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}
/* Mobile Menu */
#mobile-menu.show {
  display: block;
  animation: fadeIn 0.4s ease-in-out;
}
/* Fade In for mobile menu */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Smooth transition for hover effects */
a:hover {
  transition: transform 0.3s ease-in-out;
  transform: translateY(-2px);
}
/* Smooth scrolling */
html {
  scroll-behavior: smooth;
}
.cta-btn {
  position: relative;
  animation: fadeInBounce 2s ease-in-out;
}
@keyframes fadeInBounce {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  60% {
    opacity: 1;
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
/* Hover Animation - Scale and Pulse Effect */
.cta-btn:hover {
  transform: scale(1.1);
  box-shadow: 0 0 20px rgba(99, 102, 241, 0.7);
}
/* Pulse Ring on Hover */
.cta-btn::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 150%;
  height: 150%;
  background: radial-gradient(circle, rgba(99, 102, 241, 0.5), transparent);
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(0.5);
  opacity: 0;
  transition: transform 0.6s ease, opacity 0.6s ease;
  z-index: -1;
}
.cta-btn:hover::before {
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
}
/* Transition for smooth scaling */
.cta-btn {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.shape {
  position: absolute;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  opacity: 0.7;
}
/* Individual Shapes */
.shape-1 {
  width: 150px;
  height: 150px;
  top: 10%;
  left: 20%;
  animation: float 10s ease-in-out infinite;
}
.shape-2 {
  width: 100px;
  height: 100px;
  top: 25%;
  left: 60%;
  animation: floatReverse 12s ease-in-out infinite;
}
.shape-3 {
  width: 200px;
  height: 200px;
  bottom: 10%;
  left: 40%;
  animation: float 15s ease-in-out infinite;
}
.shape-4 {
  width: 80px;
  height: 80px;
  bottom: 20%;
  right: 20%;
  animation: floatReverse 8s ease-in-out infinite;
}
.shape-5 {
  width: 120px;
  height: 120px;
  top: 15%;
  right: 10%;
  animation: float 18s ease-in-out infinite;
}
/* Floating Animation */
@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-30px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes floatReverse {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(30px);
  }
  100% {
    transform: translateY(0px);
  }
}
/* Optional Floating Particles */
.particle {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 50%;
  opacity: 0.8;
}
/* Particle Size and Positioning */
.particle-1 {
  width: 8px;
  height: 8px;
  top: 15%;
  left: 10%;
  animation: moveParticle 10s linear infinite;
}
.particle-2 {
  width: 10px;
  height: 10px;
  top: 40%;
  left: 70%;
  animation: moveParticle 12s linear infinite;
}
.particle-3 {
  width: 6px;
  height: 6px;
  top: 60%;
  left: 30%;
  animation: moveParticle 15s linear infinite;
}
.particle-4 {
  width: 12px;
  height: 12px;
  top: 80%;
  left: 50%;
  animation: moveParticle 20s linear infinite;
}
.particle-5 {
  width: 9px;
  height: 9px;
  top: 10%;
  left: 80%;
  animation: moveParticle 18s linear infinite;
}
/* Particle Floating Animation */
@keyframes moveParticle {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.feature-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  border-radius: 10px;
}
.feature-card:hover {
  transform: translateY(-10px) scale(1.05);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
}
/* Subtle Animations on Hover */
.feature-card i {
  transition: transform 0.3s ease-in-out;
}
.feature-card:hover i {
  transform: rotate(10deg);
}
/* Gradient Border on Top */
.feature-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 3px;
  background: linear-gradient(90deg, #6366f1, #7c3aed);
}
/* Subtle Background Effects */
.blur-3xl {
  filter: blur(100px);
}
/* Typography */
h2,
h3 {
  text-align: center;
  margin-bottom: 15px;
}
h2 {
  line-height: 1.2;
}
p {
  line-height: 1.6;
}
#trusted-companies {
  position: relative;
  overflow: hidden;
  padding-bottom: 100px;
}
/* Typography */
#trusted-companies h2 {
  font-size: 3rem;
  margin-bottom: 20px;
}
#trusted-companies p {
  font-size: 1.25rem;
  margin: 0 auto;
  color: #4a4a4a;
}
/* Floating logos */
.floating-logo {
  position: absolute;
  width: 150px;
  opacity: 0.8;
  transition: transform 1.5s ease, opacity 1.5s ease;
}
.floating-logo img {
  width: 100%;
  height: auto;
  transition: transform 0.3s ease, filter 0.3s ease;
  filter: grayscale(100%);
}
/* On hover, logos come into focus */
.floating-logo:hover img {
  transform: scale(1.1);
  filter: grayscale(0%);
}
/* Parallax and floating effect */
.logo-1 {
  top: 10%;
  left: 15%;
  transform: translateY(0);
}
.logo-2 {
  top: 30%;
  left: 35%;
  transform: translateY(-30px);
}
.logo-3 {
  top: 60%;
  left: 20%;
  transform: translateY(-60px);
}
.logo-4 {
  top: 15%;
  right: 10%;
  transform: translateY(-10px);
}
.logo-5 {
  top: 40%;
  right: 25%;
  transform: translateY(-50px);
}
.logo-6 {
  bottom: 20%;
  left: 40%;
  transform: translateY(-40px);
}
.logo-7 {
  bottom: 10%;
  right: 20%;
  transform: translateY(-20px);
}
.logo-8 {
  bottom: 5%;
  right: 5%;
  transform: translateY(-30px);
}
/* Floating animation on scroll */
#trusted-companies {
  perspective: 1000px;
}
@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0px);
  }
}
.floating-logo {
  animation: float 8s ease-in-out infinite;
}
/* Responsive layout for floating logos */
@media (max-width: 768px) {
  .floating-logo {
    width: 100px;
  }
  .logo-1,
  .logo-2,
  .logo-3,
  .logo-4,
  .logo-5,
  .logo-6,
  .logo-7,
  .logo-8 {
    transform: none;
    position: relative;
    margin: 0 auto 20px;
    animation: none;
  }
}

.pricing-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  backdrop-filter: blur(10px);
}
.pricing-card:hover {
  transform: scale(1.05);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
}
/* Badge styling for most popular plan */
.pricing-card .absolute {
  font-size: 0.875rem;
  padding: 0.5rem 1rem;
  font-weight: bold;
}
.testimonial-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  backdrop-filter: blur(10px);
}
.testimonial-card:hover {
  transform: scale(1.05);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
}
.faq-item {
  position: relative;
  margin-bottom: 1rem;
}
.faq-question {
  transition: background-color 0.4s ease;
  padding-right: 40px;
  position: relative;
}
.faq-question i {
  transition: transform 0.4s ease;
}
.faq-question:hover {
  background-color: rgba(237, 242, 247, 0.5); /* Soft background on hover */
}
.faq-answer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.6s ease;
}
.faq-item.active .faq-answer {
  max-height: 200px; /* Adjust based on content */
}
.faq-item.active .faq-question i {
  transform: rotate(180deg); /* Icon rotation on active */
}
@keyframes textReveal {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
/* Keyframes for shape animations */
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes pulse {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/* Smooth reveal effect for text */
.text-reveal {
  animation: textReveal 1s ease-out forwards;
  opacity: 0;
}
/* Delays for staggering text reveal */
.hero-text .text-reveal:nth-child(1) {
  animation-delay: 0.2s;
}
.hero-text + p .text-reveal:nth-child(1) {
  animation-delay: 0.4s;
}
.hero-text + p .text-reveal:nth-child(2) {
  animation-delay: 0.6s;
}
/* Shape animations */
.rotate-animation {
  animation: rotate 20s linear infinite;
}
.rotate-reverse-animation {
  animation: rotate 20s linear infinite reverse;
}
.pulse-animation {
  animation: pulse 8s ease-in-out infinite;
}
.fade-in-animation {
  animation: fadeIn 3s ease-in-out forwards;
}
/* CTA button floating and pulsing effect */
.cta-btn {
  animation: pulse 3s infinite ease-in-out;
  position: relative;
  z-index: 10;
  transition: transform 0.3s ease;
}
.cta-btn:hover {
  transform: scale(1.2);
  box-shadow: 0 0 20px rgba(99, 102, 241, 0.7);
}
/* Shape and particle styles */
.shape {
  position: absolute;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  opacity: 0.7;
}
.shape-1 {
  width: 150px;
  height: 150px;
  top: 10%;
  left: 20%;
}
.shape-2 {
  width: 100px;
  height: 100px;
  top: 25%;
  left: 60%;
}
.shape-3 {
  width: 200px;
  height: 200px;
  bottom: 10%;
  left: 40%;
}
.shape-4 {
  width: 80px;
  height: 80px;
  bottom: 20%;
  right: 20%;
}
.shape-5 {
  width: 120px;
  height: 120px;
  top: 15%;
  right: 10%;
}
.particle {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 50%;
  opacity: 0.8;
}
.particle-1 {
  width: 8px;
  height: 8px;
  top: 15%;
  left: 10%;
}
.particle-2 {
  width: 10px;
  height: 10px;
  top: 40%;
  left: 70%;
}
.particle-3 {
  width: 6px;
  height: 6px;
  top: 60%;
  left: 30%;
}
.particle-4 {
  width: 12px;
  height: 12px;
  top: 80%;
  left: 50%;
}
.particle-5 {
  width: 9px;
  height: 9px;
  top: 10%;
  left: 80%;
}
#header {
  background-color: transparent;
  transition: background-color 0.5s ease, color 0.5s ease;
}
/* Scrolled Header with Gradient and Text Color */
/* Make Links White on Scroll */
#header a {
  transition: color 0.5s ease;
}
.text-white {
  color: white;
}
.bg-scrolled {
  background: linear-gradient(
    to right,
    #6366f1,
    #7c3aed
  ); /* Blue-purple gradient */
  opacity: 1; /* Ensure full opacity */
  backdrop-filter: none; /* Disable any blur effect on scroll */
  color: white;
  transition: background 0.5s ease, color 0.5s ease;
}
.bubble {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: 50%;
  animation: float 15s infinite ease-in-out;
}
/* Bubble Sizes and Initial Positions */
.bubble-1 {
  width: 100px;
  height: 100px;
  bottom: 10%;
  left: 20%;
  animation-duration: 18s;
}
.bubble-2 {
  width: 150px;
  height: 150px;
  top: 25%;
  left: 60%;
  animation-duration: 22s;
}
.bubble-3 {
  width: 80px;
  height: 80px;
  bottom: 40%;
  right: 30%;
  animation-duration: 12s;
}
.bubble-4 {
  width: 120px;
  height: 120px;
  top: 60%;
  left: 35%;
  animation-duration: 17s;
}
.bubble-5 {
  width: 140px;
  height: 140px;
  bottom: 15%;
  right: 10%;
  animation-duration: 20s;
}
.bubble-6 {
  width: 90px;
  height: 90px;
  top: 5%;
  right: 50%;
  animation-duration: 18s;
}
.bubble-7 {
  width: 160px;
  height: 160px;
  bottom: 5%;
  left: 10%;
  animation-duration: 22s;
}
.bubble-8 {
  width: 110px;
  height: 110px;
  top: 80%;
  left: 80%;
  animation-duration: 19s;
}
.bubble-9 {
  width: 130px;
  height: 130px;
  bottom: 30%;
  left: 50%;
  animation-duration: 16s;
}
.bubble-10 {
  width: 95px;
  height: 95px;
  top: 40%;
  right: 70%;
  animation-duration: 21s;
}
/* Keyframes for floating bubbles */
@keyframes float {
  0% {
    transform: translateY(0);
    opacity: 0.8;
  }
  50% {
    transform: translateY(-30px);
    opacity: 1;
  }
  100% {
    transform: translateY(0);
    opacity: 0.8;
  }
}
.resource-item:hover .additional-content {
  opacity: 1; /* Show additional content smoothly */
  max-height: 100px; /* Smoothly expand to show content */
  padding-top: 12px; /* Add padding smoothly */
}
.resource-item .additional-content {
  max-height: 0; /* Initially hidden */
  overflow: hidden; /* Ensure no content spills */
  transition: max-height 0.7s ease-in-out, opacity 0.7s ease-in-out,
    padding 0.7s ease-in-out;
}
/* Hide the original content when hovered */
.resource-item:hover .original-content {
  opacity: 0; /* Hide original content */
  transition: opacity 0.5s ease-in-out;
}
#onboarding-modal {
  opacity: 0;
  transition: opacity 0.5s ease;
}
#onboarding-modal.show {
  display: flex;
  opacity: 1;
}
input,
textarea {
  transition: all 0.3s ease;
}
input:focus,
textarea:focus {
  box-shadow: 0 0 10px rgba(99, 102, 241, 0.5);
}
/* Advanced Button Design */
button[type="submit"] {
  position: relative;
  z-index: 1;
  overflow: hidden;
}
button[type="submit"]::before {
  content: "";
  position: absolute;
  top: 50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(90deg, #6366f1, #7c3aed);
  transition: all 0.4s ease;
  transform: translateY(-50%);
  z-index: -1;
}
button[type="submit"]:hover::before {
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
/* Floating Shadows */
.bg-white {
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.15);
}
.bg-white:hover {
  box-shadow: 0 25px 60px rgba(0, 0, 0, 0.2);
}
/* Responsive Modal */
@media (min-width: 768px) {
  #onboarding-modal .bg-white {
    width: 60%;
  }
}
@keyframes bounceIn {
  0%,
  20%,
  40%,
  60%,
  80%,
  100% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }
  40% {
    transform: scale3d(0.9, 0.9, 0.9);
  }
  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
    transform: scale3d(0.97, 0.97, 0.97);
  }
  100% {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}
/* Center the modal */
#thank-you-message {
  display: flex;
}
.animate-bounce-in {
  animation: bounceIn 1s ease-in-out forwards;
}
/* Additional Background Effects */
.bg-gradient {
  background: linear-gradient(135deg, #6366f1, #7c3aed);
}
#thank-you-message {
  display: none; /* Hidden by default */
}
#scroll-wrapper {
  position: sticky;
  top: 0;
  height: 100vh; /* Take full viewport height */
}

/* Ensure the scroll images are properly aligned */
#scroll-images {
  position: relative;
  width: 80%; /* Default for larger screens */
  height: 80%; /* Default for larger screens */
}

@media (max-width: 768px) { /* Adjust breakpoint as needed */
  #scroll-images {
    width: 100%;
    height: 100%;
  }
}

/* Scroll-driven image styles */
.scroll-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Center the image */
  max-width: 100%;
  max-height: 100%;
  object-fit: contain; /* Prevent stretching */
  transition: opacity 0.5s ease-in-out;
  opacity: 0; /* Hide all images by default */
}

/* Show the active image */
.scroll-image.active {
  opacity: 1;
}


.bubble {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: 50%;
  animation: float 15s infinite ease-in-out;
}
/* Bubble Sizes and Initial Positions */
.bubble-1 {
  width: 100px;
  height: 100px;
  bottom: 10%;
  left: 20%;
  animation-duration: 18s;
}
.bubble-2 {
  width: 150px;
  height: 150px;
  top: 25%;
  left: 60%;
  animation-duration: 22s;
}
.bubble-3 {
  width: 80px;
  height: 80px;
  bottom: 40%;
  right: 30%;
  animation-duration: 12s;
}
.bubble-4 {
  width: 120px;
  height: 120px;
  top: 60%;
  left: 35%;
  animation-duration: 17s;
}
.bubble-5 {
  width: 140px;
  height: 140px;
  bottom: 15%;
  right: 10%;
  animation-duration: 20s;
}
/* Keyframes for floating bubbles */
@keyframes float {
  0% {
    transform: translateY(0);
    opacity: 0.8;
  }
  50% {
    transform: translateY(-30px);
    opacity: 1;
  }
  100% {
    transform: translateY(0);
    opacity: 0.8;
  }
}